import {combineReducers} from 'redux'
import authentication from './authentication'
import profile from './profile'
import accountInsuranceApplications from 'v2/spas/account/insurance-applications/redux/reducers/index.tsx'
import accountInsuranceApplication from 'v2/spas/account/insurance-applications/redux/reducers/view.tsx'
import insuranceApplicationProperty from 'v2/spas/insurance-application/properties/redux/reducers/view.tsx'
import insuranceApplicationInsuranceApplicants from 'v2/spas/insurance-application/insurance-applicants/redux/reducers/index.tsx'
import insuranceApplicationInsuranceApplicant from 'v2/spas/insurance-application/insurance-applicants/redux/reducers/view.tsx'
import accountCompany from 'v2/spas/account/companies/redux/reducers/view.tsx'
import accountAccountUser from 'v2/spas/account/account-users/redux/reducers/view.tsx'
import accountUserActivityFeedItems from 'v2/spas/account-user/activity-feed-items/redux/reducers/index.tsx'
import workflow from 'v2/spas/workflow/redux/reducers/view.tsx'

const rootReducer = combineReducers({
  authentication,
  profile,
  accountInsuranceApplications,
  accountInsuranceApplication,
  insuranceApplicationProperty,
  insuranceApplicationInsuranceApplicants,
  insuranceApplicationInsuranceApplicant,
  accountCompany,
  accountAccountUser,
  accountUserActivityFeedItems,
  workflow
})

export default rootReducer
