import React from 'react'
import { CardGrid } from 'grids'
import Col from 'react-bootstrap/Col'
import { GridSectionHeading } from 'headings'
import { AssociationCard } from 'cards'
import { Safe } from 'react-bootstrap-icons';
import { useCurrentAccount } from 'functions'

function Grid() {

  let currentAccount = useCurrentAccount()
  let insuranceBrokerAccount = currentAccount.insuranceBrokerAccount || {}

  let counters = insuranceBrokerAccount.counters || {}

  let insuranceApplicationsCounter = counters.insuranceApplications || {}
  
  return (
    <CardGrid>
      <Col lg='12'>
        <GridSectionHeading
          heading='What do you want to do?'
          description='Here are some helpful shortcuts, to point you in the right direction'
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/accounts/${currentAccount.id}/insurance-applications`}
          heading='Insurance applications'
          icon={<Safe size={50} />}
          description='Review your outstanding insurance applications'
          count={insuranceApplicationsCounter.active}
        />
      </Col>
    </CardGrid>
  )
}

export default Grid