import update from 'immutability-helper'
import { FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_REQUEST } from 'v2/spas/insurance-application/insurance-applicants/redux/action-types.tsx'
import { FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_SUCCESS } from 'v2/spas/insurance-application/insurance-applicants/redux/action-types.tsx'
import { FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_FAILURE } from 'v2/spas/insurance-application/insurance-applicants/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  insuranceApplication: {},
  insuranceApplicants: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        insuranceApplication: {
          $set: action.payload.insuranceApplication,
        },
        insuranceApplicants: {
          $set: action.payload.insuranceApplication.insuranceApplicants
        },
      })
    }

    default:
      return state
  }
}
