import update from 'immutability-helper'
import { FETCH_INSURANCE_APPLICANT_REQUEST } from 'v2/spas/insurance-application/insurance-applicants/redux/action-types.tsx'
import { FETCH_INSURANCE_APPLICANT_SUCCESS } from 'v2/spas/insurance-application/insurance-applicants/redux/action-types.tsx'
import { FETCH_INSURANCE_APPLICANT_FAILURE } from 'v2/spas/insurance-application/insurance-applicants/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  insuranceApplicant: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INSURANCE_APPLICANT_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INSURANCE_APPLICANT_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INSURANCE_APPLICANT_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        insuranceApplicant: {
          $set: action.payload.insuranceApplicant,
        }
      })
    }

    default:
      return state
  }
}